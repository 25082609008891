const arrayCores = [
  "Vermelho",
  "Azul",
  "Verde",
  "Amarelo",
  "Laranja",
  "Roxo",
  "Preto",
  "Branco",
  "Prata",
  "Cinza",
  "Marrom",
  "Dourado",
  "Bege",
  "Azul Marinho",
  "Vermelho Metálico",
  "Verde Limão",
  "Azul Celeste",
  "Rosa",
  "Roxo Metálico",
  "Prata Metálico",
  "Cinza Metálico",
  "Vermelho Ferrari",
  "Azul Porsche",
  "Preto Fosco",
  "Outra",
];

export default arrayCores;
