import React from "react";

function CarroLoading() {
  return (
    <div>
      <img
        src="https://cdn.dribbble.com/users/2245289/screenshots/6835230/dribbble.gif"
        alt="this slowpoke moves"
        width="130"
      />
      <p>
        <small>Carregando...</small>
      </p>
    </div>
  );
}

export default CarroLoading;
