import React from "react";

const Error = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div id="diverror">
            <img
              src="../../assets/img/dd.png"
              alt="Rich Logo"
              className="w-50"
            />
            <h1 id="fonteDiferente">Página em construção</h1>
            <h2 id="h2error">Desculpe! Estamos trabalhando nisso</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;